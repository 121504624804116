import React, {useEffect, useRef, useState} from 'react';
import { gsap } from 'gsap';
import './DiscoParty.css'
import audi from './disco.mp3'

const FallingBalls = ({ handleDiscoClick, isCardOpen }) => {
    const ballRef = useRef([]);
    const audio = new Audio(audi);

    useEffect(() => {
        if (handleDiscoClick && isCardOpen) {
        audio.play();
        const colors = [
            "radial-gradient(50% 50% at 50% 50%, rgba(255, 249, 0, 0.40) 0%, rgba(62, 62, 62, 0.00) 100%)",
            "radial-gradient(50% 50% at 50% 50%, rgba(255, 0, 0, 0.40) 0%, rgba(62, 62, 62, 0.00) 100%)",
            "radial-gradient(50% 50% at 50% 50%, rgba(0, 199, 255, 0.40) 0%, rgba(62, 62, 62, 0.00) 100%)",
            "radial-gradient(50% 50% at 50% 50%, rgba(255, 0, 155, 0.40) 0%, rgba(62, 62, 62, 0.00) 100%)",
            "radial-gradient(50% 50% at 50% 50%, rgba(130, 53, 183, 0.40) 0%, rgba(62, 62, 62, 0.00) 100%)"]; // array of colors

        for (let i = 0; i < 50; i++) {
            const ball = document.createElement('div');
            ball.className = 'ball'; // клас для анімації
            ball.style.position = 'fixed';
            ball.style.background = colors[Math.floor(Math.random() * colors.length)];
            ball.style.width = `${Math.random() * 70}px`; // random size
            ball.style.height = ball.style.width; // keep it a circle
            ball.style.borderRadius = '50%';
            ball.style.left = `${Math.random() * window.innerWidth}px`; // random starting position
            ball.style.top = '-100px'; // start from above the screen
            document.body.appendChild(ball);
            ballRef.current.push(ball);

            gsap.to(ball, {
                y: '120vh', // animate to bottom of the screen
                duration: 1 + Math.random() * 20, // random duration for variety
                repeat: -1, // repeat indefinitely
                ease: "none" // linear movement
            });
        }

        setTimeout(()=> {
            ballRef.current.forEach(ball => {
                gsap.killTweensOf(ball);
                if (document.body.contains(ball)) {
                    document.body.removeChild(ball);
                }
            });
            handleDiscoClick(false);
        },4000);
        }
    }, [handleDiscoClick, isCardOpen]);

    return null;
};

export default FallingBalls;
