import React, { useEffect, useRef } from 'react';

const ChangeBackground = ({ handleBackClick, isCardOpen }) => {
    const colors = [
        "linear-gradient(244deg, #394254 0%, #20242F 100.14%)",
        "linear-gradient(244deg, #394254 0%, #20242F 100.14%)",
        "linear-gradient(132deg, #6D5986 2.8%, #274E53 97.52%)",
        "linear-gradient(131deg, #336C84 -1.26%, #2C4076 97.56%)",
        "linear-gradient(138deg, #526DA1 1.4%, #2B448A 85.45%)",
        "linear-gradient(151deg, #3A558C -8%, #458B87 72.67%)",
        "linear-gradient(244deg, #483954 0%, #394669 100.14%)",
        "linear-gradient(244deg, #394254 0%, #20242F 100.14%)"
    ]; // array of colors
    useEffect(() => {
        if (handleBackClick && isCardOpen) {
            const intervalId = setInterval(() => {
                const element = document.getElementsByClassName('background')[0];
                if (element) {
                    element.style.opacity = 0; // start the transition by setting opacity to 0
                    setTimeout(() => {
                        element.style.background = colors[Math.floor(Math.random() * colors.length)];
                        element.style.transition = 'opacity 2s'; // Set the transition duration to 2 seconds
                        element.style.opacity = 1; // end the transition by setting opacity back to 1
                    }, 2000); // wait for 2 seconds before changing the color
                }
                handleBackClick(false);
            }, 300); // Change the color every 4 seconds

            return () => clearInterval(intervalId);
        }
    }, [handleBackClick, isCardOpen, colors]);

    return null; // Цей компонент не рендерить нічого на екран
};

export default ChangeBackground;
