import Background from "./Background/Background";
import InContent from "./Content/Content";

function App() {
  return (
    <>
        <InContent/>
        <Background/>
    </>
  );
}

export default App;
