import React, {useEffect} from 'react';

const CustomCursor = ({ cursorImageUrl }) => {
    useEffect(() => {
        const defaultCursor = document.body.style.cursor;
        document.body.style.cursor = `url(${cursorImageUrl}), auto`;

        return () => {
            document.body.style.cursor = defaultCursor;
        };
    }, [cursorImageUrl]);

    return null;
};

export default CustomCursor;

