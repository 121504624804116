import './Content.css'
import {useState} from "react";
import FlippingCard from "../FlippingCard/FlippingCard";


function InContent(){
    const [isAnyCardFlipping, setAnyCardFlipping] = useState(false);

    return(
        <div className='content'>
            <div>
                <h1>Pick your card!</h1>
                <p>There may be something interesting behind it...</p>
            </div>

            <div className='row'>
                <FlippingCard isAnyCardFlipping={isAnyCardFlipping} setAnyCardFlipping={setAnyCardFlipping} id={0}/>
                <FlippingCard isAnyCardFlipping={isAnyCardFlipping} setAnyCardFlipping={setAnyCardFlipping} id={1}/>
                <FlippingCard isAnyCardFlipping={isAnyCardFlipping} setAnyCardFlipping={setAnyCardFlipping} id={2}/>
            </div>

            <div className='row'>
                <FlippingCard isAnyCardFlipping={isAnyCardFlipping} setAnyCardFlipping={setAnyCardFlipping} id={3}/>
                <FlippingCard isAnyCardFlipping={isAnyCardFlipping} setAnyCardFlipping={setAnyCardFlipping} id={4}/>
                <FlippingCard isAnyCardFlipping={isAnyCardFlipping} setAnyCardFlipping={setAnyCardFlipping} id={5}/>
            </div>
        </div>
    )
}

export default InContent;