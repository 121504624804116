import React, {useEffect} from 'react';
import {gsap} from 'gsap';

const EmojiRush = ({ handleEmojiClick, isCardOpen }) => {
    const emojis = ["😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇",
        "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇"
        , "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇"
        , "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇"]; // список емодзі

    useEffect(() => {
        if (handleEmojiClick && isCardOpen) {
        const emojiElements = []; // масив для зберігання елементів емодзі

        for (let i = 0; i < 100; i++) {
            const emojiElement = document.createElement('div'); // створюємо новий div для кожного емодзі
            emojiElement.textContent = emojis[i]; // встановлюємо текст емодзі
            emojiElement.style.position = 'fixed'; // встановлюємо позицію емодзі
            emojiElement.style.left = `-100px`; // випадкова горизонтальна позиція
            emojiElement.style.top = `-100px`; // випадкова вертикальна позиція
            emojiElement.style.fontSize = `5rem`; // розмір шрифту емодзі
            emojiElement.style.zIndex = -1; // z-index, щоб емодзі були над іншими елементами
            emojiElement.className = 'emoji'; // клас для анімації
            document.body.appendChild(emojiElement); // додаємо емодзі до body
            emojiElements.push(emojiElement); // додаємо емодзі до масиву

            // створюємо анімацію bounce за допомогою GSAP
            gsap.to(emojiElement, {
                x: `${Math.random() * 100}vw`,
                y: `${Math.random() * 100}vh`,
                duration: 3 // тривалість анімації в секундах
            });
        }

        // видаляємо емодзі після 3 секунд
        setTimeout(() => {
            emojiElements.forEach(emojiElement => {
                gsap.killTweensOf(emojiElement); // зупиняємо всі анімації GSAP для емодзі
                document.body.removeChild(emojiElement);
            });
            handleEmojiClick(false);
        }, 4000);
        }
    }, [handleEmojiClick, isCardOpen]);

    return null;
};

export default EmojiRush;
