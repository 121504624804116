import './Background.css'

function Background(){
    const myStyle = {'--i':11};
    const myStyle1 = {'--i': 13};
    const myStyle2 = {'--i': 12};
    const myStyle3 = {'--i': 14};
    const myStyle4 = {'--i': 23};
    const myStyle5 = {'--i': 19};
    const myStyle6 = {'--i': 15};
    const myStyle7 = {'--i': 16};
    const myStyle8 = {'--i': 17};
    const myStyle9 = {'--i': 8};
    const myStyle10 = {'--i': 17};
    const myStyle11 = {'--i': 15};
    const myStyle12 = {'--i': 12};
    const myStyle13 = {'--i': 13};
    const myStyle14 = {'--i': 25};

    return(
        <div className='back_backround'>
        <div className='background'>
            <div className='bubbles'>
                <span style={myStyle}></span>
                <span style={myStyle1}></span>
                <span style={myStyle2}></span>
                <span style={myStyle3}></span>
                <span style={myStyle4}></span>
                <span style={myStyle5}></span>
                <span style={myStyle6}></span>
                <span style={myStyle7}></span>
                <span style={myStyle8}></span>
                <span style={myStyle9}></span>
                <span style={myStyle10}></span>
                <span style={myStyle11}></span>
                <span style={myStyle12}></span>
                <span style={myStyle13}></span>
                <span style={myStyle14}></span><span style={myStyle}></span><span style={myStyle}></span>
                <span style={myStyle1}></span>
                <span style={myStyle2}></span>
                <span style={myStyle3}></span>
                <span style={myStyle4}></span>
                <span style={myStyle5}></span>
                <span style={myStyle6}></span>
                <span style={myStyle7}></span>
                <span style={myStyle8}></span>
                <span style={myStyle9}></span>
                <span style={myStyle10}></span>
                <span style={myStyle11}></span>
                <span style={myStyle12}></span>
                <span style={myStyle2}></span>
                <span style={myStyle3}></span>
                <span style={myStyle4}></span>
                <span style={myStyle5}></span>
                <span style={myStyle6}></span>
                <span style={myStyle7}></span>
                <span style={myStyle8}></span>
                <span style={myStyle9}></span>
            </div>
        </div>
        </div>
    )
}

export default Background;