import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './jokes.css'
function JokeMoment ({ handleJokeClick, isCardOpen })  {
    const [joke, setJoke] = useState('');
    const jokes = [
        "What do kids play when their mom is using the phone? Bored games.",
        "What do you call an ant who fights crime? A vigilANTe!",
        "Why are snails slow? Because they’re carrying a house on their back.",
        "What’s the smartest insect? A spelling bee!",
        "What does a storm cloud wear under his raincoat? Thunderwear.",
        "What is fast, loud and crunchy? A rocket chip.",
        "How does the ocean say hi? It waves!",
        "What do you call a couple of chimpanzees sharing an Amazon account? PRIME-mates.",
        "Why did the teddy bear say no to dessert? Because she was stuffed.",
        "Name the kind of tree you can hold in your hand? A palm tree!",
        "What do birds give out on Halloween? Tweets.",
        "What has ears but cannot hear? A cornfield.",
        "What’s a cat’s favorite dessert? A bowl full of mice-cream.",
        "What did the policeman say to his hungry stomach? “Freeze. You’re under a vest.",
        "What did the left eye say to the right eye? Between us, something smells!",
        "What do you call a guy who’s really loud? Mike.",
        "Why do birds fly south in the winter? It’s faster than walking!",
        "What did the lava say to his girlfriend? “I lava you!”",
        "Why did the student eat his homework? Because the teacher told him it was a piece of cake.",
        "Why did the soccer player take so long to eat dinner? Because he thought he couldn’t use his hands."
    ]

    useEffect(() => {
        if (handleJokeClick && isCardOpen) {
            const randomIndex = Math.floor(Math.random() * jokes.length);
            setJoke(jokes[randomIndex]);

            const timer = setTimeout(() => {
                setJoke('');
                handleJokeClick(false); // Reset isButtonClicked2 to false
            }, 3000);

            return () => clearTimeout(timer); // Очищуємо таймер при розмонтуванні
        }
    }, [handleJokeClick, isCardOpen, jokes]);

    return joke ? ReactDOM.createPortal(
        <div className='joke'>{joke}</div>,
        document.body
    ) : null;
};

export default JokeMoment