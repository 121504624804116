import React, { useState } from 'react';
import './FlippingCard.css';
import EmojiRush from "../functions/EmojiRush/EmojiRush";
import JokeMoment from "../functions/JokeMoment/JokeMoment";
import DiscoMode from "../functions/DiscoParty/DiscoParty";
import DiscoParty from "../functions/DiscoParty/DiscoParty";
import ColorBalls from "../functions/DiscoParty/DiscoParty";
import CustomCursor from "../functions/Cursor/Cursor";
import pht1 from '../../src/cursor-images/1.png'
import pht2 from '../../src/cursor-images/2.png'
import pht3 from '../../src/cursor-images/3.png'
import pht4 from '../../src/cursor-images/4.png'
import pht5 from '../../src/cursor-images/5.png'
import pht6 from '../../src/cursor-images/6.png'
import pht7 from '../../src/cursor-images/7.png'
import Memes from "../functions/Memes/Memes";
import changeBackground from "../functions/ChangeBackground/ChangeBackground";
import ChangeBackground from "../functions/ChangeBackground/ChangeBackground";

const FlippingCard = ({ isAnyCardFlipping, setAnyCardFlipping, id}) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [text, setText] = useState();
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [isButtonClicked2, setButtonClicked2] = useState(false);
    const [isButtonClicked3, setButtonClicked3] = useState(false);
    const [isButtonClicked4, setButtonClicked4] = useState(false);
    const [isButtonClicked5, setButtonClicked5] = useState(false);
    const [isButtonClicked6, setButtonClicked6] = useState(false);
    const cursors = [pht1, pht2, pht3, pht4, pht5, pht6, pht7]
    const randomin = Math.floor(Math.random() * cursors.length);
    const emoji = () => "Emoji RUUUUUSH";
    const joke = () => "A little joke";
    const cursor = () => "Oh... Your cursor!";
    const meme = () => "MEME MOMENT";
    const disco = () => "DISCO PARTY TIME!!!";
    const back = () => '?BACKGROUND?'

    const functionsArray = [emoji, joke, cursor, meme, disco, back];

    const [isCardClicked, setIsCardClicked] = useState(false);
    const handleClick = () => {
        if (!isAnyCardFlipping) {
            setText(functionsArray[id]());
            if (id === 0) {
                setButtonClicked(!isButtonClicked);
                setIsCardClicked(!isCardClicked);
            }
            if (id === 1) {
                setButtonClicked2(!isButtonClicked2);
                setIsCardClicked(!isCardClicked);
            }
            if (id === 4) {
                setButtonClicked5(!isButtonClicked5);
                setIsCardClicked(!isCardClicked);
            }
            if (id === 2) {
                setButtonClicked3(!isButtonClicked3);
            }
            if (id === 3) {
                setButtonClicked4(!isButtonClicked4);
                setIsCardClicked(!isCardClicked);
            }
            if (id === 5) {
                setButtonClicked6(!isButtonClicked6);
                setIsCardClicked(!isCardClicked);
            }
            setIsFlipped(true);
            setAnyCardFlipping(true);
            setTimeout(() => {
                setIsFlipped(false);
                setTimeout(() => {
                    setAnyCardFlipping(false);
                }, 2000);
            }, 4000);
        }
    };

    return (
        <div className={`flipping_card ${isFlipped ? 'flip' : ''}`} onClick={handleClick}>
            {isButtonClicked && <EmojiRush handleEmojiClick={setButtonClicked} isCardOpen={isFlipped}/>}
            {isButtonClicked2 && <JokeMoment handleJokeClick={setButtonClicked2} isCardOpen={isFlipped}/>}
            {isButtonClicked3 && <CustomCursor cursorImageUrl={cursors[randomin]}/>}
            {isButtonClicked5 && <ColorBalls handleDiscoClick={setButtonClicked5} isCardOpen={isFlipped}/>}
            {isButtonClicked4 && <Memes handleMemeClick={setButtonClicked4} isCardOpen={isFlipped}/>}
            {isButtonClicked6 && <ChangeBackground handleBackClick={setButtonClicked6} isCardOpen={isFlipped}/>}
            <div className='front'>
            </div>
            <div className='back'>
                <text>{text}</text>
            </div>
        </div>
    );
};

export default FlippingCard;
