import React, { useEffect } from 'react';
import { gsap } from 'gsap';

import pht1 from '../../memes/png/funny-text-memes-2.jpg'
import pht2 from '../../memes/png/funny-text-memes-3.jpg'
import pht3 from '../../memes/png/funny-text-memes-4.jpg'
import pht4 from '../../memes/png/funny-text-memes-10.jpg'
import pht5 from '../../memes/png/funny-text-memes-12.jpg'
import pht6 from '../../memes/png/funny-text-memes-13.jpg'
import pht7 from '../../memes/png/funny-text-memes-14.jpg'
import pht8 from '../../memes/png/funny-text-memes-15.jpg'
import pht9 from '../../memes/png/funny-text-memes-16.jpg'
import pht10 from '../../memes/png/funny-text-memes-17.jpg'
import pht11 from '../../memes/png/funny-text-memes-18.jpg'
import pht12 from '../../memes/png/funny-text-memes-19.jpg'
import pht13 from '../../memes/png/funny-text-memes-21.jpg'
import pht14 from '../../memes/png/funny-text-memes-22.jpg'
import pht15 from '../../memes/png/funny-text-memes-24.jpg'
import pht16 from '../../memes/png/funny-text-memes-25.jpg'



const Memes = ({ handleMemeClick, isCardOpen }) => {
    const gifs = [pht1,pht2,pht3,pht4,pht5,pht6,pht7,pht8,pht9,pht10,pht11,pht12,pht13,pht14,pht15,pht16]; // вибрана гіфка
    const randomIndex = Math.floor(Math.random() * gifs.length);



    useEffect(() => {
        if (handleMemeClick && isCardOpen) {
            const gifElement = document.createElement('img');
            gifElement.src = gifs[randomIndex];
            gifElement.style.position = 'fixed';
            gifElement.style.left = '50%';
            gifElement.style.top = '50%';
            gifElement.style.transform = 'translate(-50%, -50%)';
            gifElement.className = 'emojirush';
            document.body.appendChild(gifElement);

            gsap.to(gifElement, {
                opacity: 2,
                duration: 5,
                onComplete: () => {
                    document.body.removeChild(gifElement);
                    handleMemeClick(false);
                }
            });
        }
    }, [handleMemeClick, isCardOpen, gifs, randomIndex]);

    return null;
};

export default Memes;
